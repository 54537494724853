import { ref, reactive, watch } from 'vue';
import { apiEgpmTaskResultPage } from '@/request/egpmApi';
import { useRoute } from 'vue-router';
import withLoading from '@/utils/loading';

export default ({ resultStore }) => {

    const route = useRoute();
    const query = reactive({
        pageNum: 1,
        pageSize: 10,
    })
    const total = ref(0);
    const tableData = ref([]); // 表格数据

    // 获取表格数据
    async function getTableData() {
        const params = { ...resultStore.sreen, ...query, taskId: route.query.id }
        const { data } = await withLoading(apiEgpmTaskResultPage)(params);
        total.value = data?.total || 0;
        tableData.value = data?.list || [];
    }

    // 表格搜索
    function search() {
        query.pageNum = 1;
        getTableData();
    }

    // 分页
    watch(() => query.pageNum, getTableData)

    getTableData();

    return { query, total, tableData, search }
}