import { apiEgpmDownloadCreate } from '@/request/egpmApi';
import withLoading from '@/utils/loading';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

export default ({ resultStore }) => {

    const router = useRouter();
    const route = useRoute();

    // 创建下载任务-单期
    async function handleDownload(taskId, flag, other = {}) {
        const { code } = await withLoading(apiEgpmDownloadCreate)({ taskId, flag, ...resultStore.sreen, ...other })
        if (code !== '0') return;
        ElMessage.success('创建下载任务成功');
        const href = router.resolve({
            path: `/egpm/download`,
            query: { reportId: route.query.reportId },
        }).href;
        window.open(href, "_blank");
    }

    return { handleDownload }
}