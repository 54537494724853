<template>
    <div class="table_line_chart" ref="chartDom"></div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import echarts from "@/utils/echarts";
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
});

const chartDom = ref(null);
let myChart = null;

function init() {
    myChart = echarts.init(chartDom.value);
    const option = {
        xAxis: {
            show: false,
            type: "category",
        },
        yAxis: {
            show: false,
            type: "value",
        },
        grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        series: [
            {
                data: props.data,
                type: "line",
                symbolSize: 0,
                smooth: true,
            },
        ],
    };
    option && myChart.setOption(option);
    setTimeout(() => {
        myChart.resize();
    });
    window.addEventListener("resize", function () {
        myChart.resize();
    });
}

watch(
    () => props.data,
    () => {
        handleDispose();
        init();
    }
);

// 销毁
function handleDispose() {
    myChart && myChart.dispose();
    myChart = null;
    window.removeEventListener("resize", function () {});
}

onMounted(() => {
    init();
});

onBeforeUnmount(() => {
    handleDispose();
});
</script>

<style scoped lang="scss">
.table_line_chart {
    width: 100%;
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin: 0 auto;
}
</style>
