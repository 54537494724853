<template>
    <div class="single_page">
        <!-- 操作栏 -->
        <div class="operation">
            <el-button type="primary" style="margin-left: 15px" @click="handleDownload($route.query.id, 1)">导出为excel</el-button>
        </div>
        <!-- 表格 -->
        <div class="table_box">
            <el-table :data="tableData" stripe border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" height="600">
                <el-table-column prop="dishName" label="菜品" />
                <el-table-column prop="batchTotal" label="连续期数" />
                <el-table-column prop="name" label="菜品潜力指数连续趋势" align="center">
                    <template #default="scope">
                        <TableLineChart v-if="scope.row.trend?.length > 1" :data="scope.row.trend || []" />
                        <!-- <span v-else>{{ scope.row.trend?.join("") || "" }}</span> -->
                    </template>
                </el-table-column>
                <el-table-column prop="shopRatio" label="门店渗透率">
                    <template #default="scope">
                        <span>{{ formatPercent(scope.row.shopRatio) }}%</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="name" label="关注程度" /> -->
                <el-table-column prop="name" label="图片" width="180px">
                    <template #default="scope">
                        <el-image
                            style="width: 150px; height: 70px"
                            :src="scope.row.dishImgUrl"
                            :preview-src-list="[scope.row.dishImgUrl]"
                            fit="cover"
                            v-if="scope.row.dishImgUrl"
                            @error="
                                () => {
                                    scope.row.dishImgUrl = null;
                                }
                            "
                        >
                        </el-image>
                        <div class="default_img" v-else><PictureFilled class="icon" /></div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_box">
                <el-pagination layout="prev, pager, next, total, jumper" background :total="total" :page-size="query.pageSize" v-model:current-page="query.pageNum"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
import useTable from "./useTable";
import TableLineChart from "@/components/egpm/TableLineChart.vue";
import useResultStore from "@/store/egpm/useResultStore";
import { formatPercent } from "@/utils/egpm";
import useDownload from "../useDownload";
import { PictureFilled } from "@element-plus/icons-vue";

const resultStore = useResultStore();
// 注册主组件搜索事件
resultStore.setSearchEvent(() => {
    search();
});

// 表格
const { query, total, tableData, search } = useTable({ resultStore });

// 创建下载任务
const { handleDownload } = useDownload({ resultStore });
</script>

<style lang="scss" scoped>
.single_page {
    .operation {
        display: flex;
        justify-content: flex-end;
    }
    .chart_box {
        height: 600px;
    }
    .table_box {
        margin-top: 15px;
        .page_box {
            padding-top: 30px;
            padding-bottom: 15px;
            display: flex;
            justify-content: flex-end;
        }
        .default_img {
            width: 150px;
            height: 70px;
            background-color: #f2f2f2;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                width: 3em;
                height: 3em;
                color: #999;
            }
        }
    }
}
</style>
